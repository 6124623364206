import React from 'react'

import { graphql } from 'gatsby'

import Layout from '../components/layout'

import UploadCVForm from '../components/upload-cv-form'

import SEO from '../components/seo'

export default ({ data }) => {
    let page = data.allWordpressPage.edges[0].node

    return (
        <Layout>
            <SEO
                title={page.yoast_meta.yoast_wpseo_title}
                description={page.yoast_meta.yoast_wpseo_metadesc}
            />
            <UploadCVForm />
        </Layout>
    )
}

export const query = graphql`
    {
        allWordpressPage(filter: { slug: { eq: "upload-cv" } }) {
            edges {
                node {
                    yoast_meta {
                        yoast_wpseo_title
                        yoast_wpseo_metadesc
                    }
                }
            }
        }
    }
`
