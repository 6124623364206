import React from 'react'

import Form from '../form'

import Input from '../input'

import styles from './style.module.scss'

import Button from '../button'

import CVImage from '../../images/cv.svg'

import PhoneInput from '../phone-input'
import { addCv } from '../form/wpActions'

export default () => {
    return (
        <div className={styles.postEnquiryForm}>
            <div className="wrapper">
                <div className="row">
                    <div className="col-xs-12 col-sm-6">
                        <h1>Upload your CV</h1>
                        <Form
                            className={styles.form}
                            onSubmit={async data => {
                                await addCv(data)
                            }}
                            requiredFields={[
                                'name',
                                'designation',
                                'experience',
                                'expected_salary'
                            ]}
                            errorMessageClass={styles.errorMessage}
                        >
                            <p>
                                Your next hire is here. We help thousands of
                                real estate job seekers and employers find the
                                right fit every day. Start hiring now on the
                                world's #1 real estate portal.
                            </p>

                            <Input type="text" label="Name" required />
                            <Input
                                type="text"
                                name="email"
                                label="Email"
                                required
                            />
                            <Input
                                type="text"
                                name="designation"
                                label="Job title"
                                required
                            />
                            <Input type="text" label="Experience" required />
                            <Input
                                type="text"
                                label="Expected salary"
                                required
                            />
                            <Input type="text" label="Address" required />

                            <PhoneInput />

                            <Input
                                type="file"
                                name="file"
                                label="CV File"
                                required
                            />

                            <Button>Submit</Button>
                        </Form>
                    </div>
                    <div className="col-xs-12 col-sm-6">
                        <CVImage className={styles.question} />
                    </div>
                </div>
            </div>
        </div>
    )
}
